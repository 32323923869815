import { graphql } from 'gatsby';
import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import Post from '../templates/Post';
import Meta from '../components/Meta';
import Layout from '../components/Layout';
import Interstitial from '../components/Interstitial';
import Disclaimer from '../components/Disclaimer';

const WhatsAppRemainParty = ({ data, location }) => (
  <Layout location={location}>
    <Meta
      title="The Remain Party"
      description="🗳️ Vote REMAIN on Thursday, here's how"
      shareImage="theremainparty"
      location={location}
      site={get(data, 'site.meta')}
      whatsAppMessage={`🗳 How to VOTE REMAIN in the EU Elections, The Remain Party!

This election can be confusing for Remainers, but the truth is there is no "Remain Party", we just need as many people to vote as possible, no matter who it's for.

*AUTO SHARE THIS MESSAGE*
Getting just 2 or 3 friends to vote can make this a day we all make a difference - share this message, CLICK:
➡ https://saveourfuture.uk/remainparty

*INFO*
• You don't need your polling card to vote
• Find out where to vote https://wheredoivote.co.uk
• Polls are open 7am-10pm (What time will you vote?)

*IDEAS*
• Vote with friends or neighbours
• Make sure to get a break from work
• Post https://TheRemain.Party to your Facebook & Twitter`}
    />
    <Interstitial />
  </Layout>
);

WhatsAppRemainParty.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default WhatsAppRemainParty;

export const query = graphql`
  query RemainPartyQuery {
    site {
      meta: siteMetadata {
        title
        description
        url: siteUrl
        author
        twitter
        adsense
      }
    }
  }
`;
